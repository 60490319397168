import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/login/login.vue"),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/admin',
    component: () => import('../views/admin/index.vue'),
    children: [
      // Dashboard
      {
        name: 'Servicios',
        path: '',
        component: () => import('../views/admin/servicios.vue'),
      },
      // Pages
      {
        name: 'Reservas',
        path: 'reservas',
        component: () => import('../views/admin/reservas.vue'),
      }
    ],
    meta: {
      requiresAuth: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
