<template>
  <section id="hero">
    <v-parallax src="@/assets/img/portada2.png" height="850">
      <v-row style="margin-top: 140px" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              md="5"
              xl="8"
              style="
                color: #000;
                background-color: rgb(30 30 30 / 43%);
                border-radius: 10px;
              "
            >
              <!-- <h1 class="display-2 font-weight-bold mb-4">Apunto GA</h1> -->
              <div class="font-weight-light" style="color: #fff">
                En esta empresa somos
                <span style="font-weight: bold">Positivos</span>
                <span style="font-weight: bold"> Los problemas son RETOS,</span>
                no obstáculos Compartimos los triunfos. Trabajamos en
                <span style="font-weight: bold">EQUIPO</span> Amamos los que
                hacemos Somos
                <span style="font-weight: bold">PROACTIVOS</span> Nos ponemos en
                <span style="font-weight: bold">TÚ</span> lugar
              </div>
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#features')"
                class="mt-5"
              >
                mas
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="7" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <!-- <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col> -->

            <v-col cols="12" sm="4" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    src="@/assets/img/mision.png"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">MISIÓN</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    Proporcionar un servicio de alta calidad con métodos de
                    trabajo innovadores, optimizando el tiempo de estadía del
                    vehículo. Asesorar a nuestros clientes con experiencia y
                    honestidad.
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    src="@/assets/img/vision.png"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">VISIÓN</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    Ser una empresa líder, que acompañada de nuestro equipo
                    profesional nos permita estar siempre a la vanguardia del
                    sector y ser referente en la región.
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    src="@/assets/img/valores.png"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">VALORES</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    - Honestidad y Empatía para asistir a nuestros clientes
                    <br />- Responsabilidad con nuestro trabajo <br />- Iniciar
                    cualquier trabajo con Experiencia y Compromiso <br />-
                    Calidez Humana con nuestro entorno.
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card>
        <!-- <youtube
          :video-id="videoId"
          @ready="ready"
          @playing="playing"
        ></youtube> -->
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "i8IvvHJssWE",
      features: [
        {
          img: require("@/assets/img/mision.png"),
          title: "MISIÓN",
          text: "Proporcionar un servicio de alta calidad con métodos de trabajo innovadores, optimizando el tiempo de estadía del vehículo. Asesorar a nuestros clientes con experiencia y honestidad.",
        },
        {
          img: require("@/assets/img/vision.png"),
          title: "VISIÓN",
          text: "Ser una empresa líder, que acompañada de nuestro equipo profesional nos permita estar siempre a la vanguardia del sector y ser referente en la región.",
        },
        {
          img: require("@/assets/img/valores.png"),
          title: "VALORES",
          text: "- Honestidad y Empatía para asistir a nuestros clientes \n- Responsabilidad con nuestro trabajo \n- Iniciar cualquier trabajo con Experiencia y Compromiso \n- Calidez Humana con nuestro entorno.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    // playing(event) {
    //   // The player is playing a video.
    // },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
