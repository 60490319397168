import Vue from "vue";
import Vuex from "vuex";
import app from "../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuarioAdmin: localStorage.getItem("usuarioAdmin"),
    usuario: localStorage.getItem("usuario"),
    drawer: null,
    snackbar: {
      visible: false,
      color: "success",
      text: null,
      timeout: 4000,
      multiline: false,
    },
  },
  getters: {
    loggedIn(state) {
      return state.usuarioAdmin !== null;
    },
  },
  mutations: {
    //token
    retrieveToken(state, usuarioAdmin,usuario) {
      state.usuarioAdmin = usuarioAdmin;
      state.usuario = usuario;
    },
    destroyToken(state) {
      state.usuarioAdmin = null;
      state.usuario = null;
    },
    //drawer
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    alert(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text.length && 50;

      // Si es mas de 50 caracteres definirlo como multilinea
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }

      // Color para mensajes de error o éxito
      if (payload.color) {
        state.snackbar.color = payload.color;
      }

      // Tiempo de duración
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
    },
    cerrarAlert(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
    },
  },
  actions: {
    destroyToken(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve) => {
          localStorage.removeItem("usuarioAdmin");
          localStorage.removeItem("usuario");
          resolve(true);
          context.commit("destroyToken");
        });
      }
    },
    retrieveToken(context, usuarioDto) {
      const auth = getAuth(app);
      return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, usuarioDto.usuario, usuarioDto.clave)
          .then((userCredential) => {
            resolve(userCredential.user);
            // Signed in
            const user = userCredential.user;
            localStorage.setItem("usuarioAdmin", user.accessToken);
            localStorage.setItem("usuario", usuarioDto.usuario);
            context.commit("retrieveToken", user.accessToken,usuarioDto.usuario);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
