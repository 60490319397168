<template>
  <div>
    <navigation  />  <!--:color="color" :flat="flat" -->
    <v-main class="pt-0">
      <home />
      <about />
      <download />
      <pricing />
     <Reconocimientos />
      <contact />
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </div>
</template>

<script>
import navigation from "../components/landing/Navigation.vue";
import foote from "../components/landing/Footer.vue";
import home from "../components/landing/HomeSection";
import about from "../components/landing/AboutSection";
import download from "../components/landing/DownloadSection";
import pricing from "../components/landing/PricingSection";
import contact from "../components/landing/ContactSection";
import Reconocimientos from "../components/landing/Reconocimientos.vue";

export default {
  components: {
    navigation,
    foote,
    home,
    about,
    download,
    pricing,
    contact,
    Reconocimientos
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "fondo";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
