import { initializeApp } from "firebase/app";

// import { getDatabase } from "firebase/database"
// import {getAuth} from 'firebase/auth'
const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: "apuntoga-e963f.firebaseapp.com",
    databaseURL: "https://apuntoga-e963f-default-rtdb.firebaseio.com",
    projectId: "apuntoga-e963f",
    storageBucket: "apuntoga-e963f.appspot.com",
    messagingSenderId: "489550395446",
    appId: "1:489550395446:web:e80181c43b4cd90b9a8097",
    measurementId: "G-HYVHNYH24D"
};

const app = initializeApp(firebaseConfig);
// const db = getDatabase(app);
// const auth = getAuth(app);
//const serviciosRef = ref(db,"servicios");
export default app;