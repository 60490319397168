<template>
  <v-app>
    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "./components/atoms/snackbar.vue";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  mounted(){
    document.title = 'Apunto Grupo Automotriz'
  }
};
</script>
