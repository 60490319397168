<template>
  <v-snackbar top vertical v-model="snackbar.visible" :timeout="snackbar.timeout" :multi-line="snackbar.multiline === true" :color="snackbar.color"
                right transition="slide-y-transition" >
    {{ snackbar.text }}
  <v-btn text="" dark="" @click="cerrarAlert">Cerrar</v-btn>
  </v-snackbar>
 
</template>
<script>
import { mapMutations } from "vuex";
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    }
  },
  methods: {
    ...mapMutations(["cerrarAlert"])
  }
};
</script>