import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from "vuetify/es5/locale/es";
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: "es",
      },
      theme: {
        options: { customProperties: true },
        themes: {
          light: {
            primary: "#FE2C2C",
            secondary: "#141313",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#039BE5",
            success: "#4CAF50",
            warning: "#FFC107",
            fondo: "#ffffff",
          },
          dark: {
            primary: "#000000",
            background: "#E0E0E0",
          },
        },
      },
});
