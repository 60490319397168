<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-row>
        <v-col class="reco col-12 col-md-6">
          <v-card-text class="white--text pt-0">
            Datos de Contacto
          </v-card-text>
          <v-card-text class="text-left">
            <div class="d-flex justify-start">
              <v-btn class="mx-4 white--text" href="" target="_blank" icon>
                <v-icon size="24px">mdi-home</v-icon>
              </v-btn>
              <div class="contacto">
                La Paz, Zona Sur, Cota Cota, Calle 30 Nro 282
              </div>
            </div>
            <!-- <div class="d-flex justify-start">
              <v-btn
                class="mx-4 white--text"
                href="https://goo.gl/maps/oYhzRbCNc8dYHD9B9"
                target="_blank"
                text
              >
                <v-icon size="24px">mdi-google-maps</v-icon>
                Ver Mapa ...
              </v-btn>
            </div> -->
            <div class="d-flex justify-start">
              <v-btn class="mx-4 white--text" href="" target="_blank" icon>
                <v-icon size="24px">mdi-email</v-icon>
              </v-btn>
              <div class="contacto">servicios@apuntoga.com</div>
            </div>
            <div class="d-flex justify-start">
              <v-btn
                class="mx-4 white--text"
                href="https://goo.gl/maps/oYhzRbCNc8dYHD9B9"
                target="_blank"
                icon
              >
                <v-icon size="24px">mdi-card-account-mail-outline</v-icon>
              </v-btn>
              <div class="contacto">67195902 - 68921944 - 2770373</div>
            </div>
          </v-card-text>
        </v-col>
        <v-col class="reco col-12 col-md-6">
          <v-card-text class="white--text pt-0">
            Nuestras Redes Sociales
          </v-card-text>
          <v-card-text>
            <v-btn
              v-for="(icon, i) in icons"
              :key="i"
              class="mx-4 white--text"
              :href="icon.link"
              target="_blank"
              text
            >
              {{ icon.info }}
              <v-icon size="24px">{{ icon.text }}</v-icon>
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Apunto GA</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
.contacto {
  font-size: 12px;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/apunto.ga",
        info: "Facebook",
      },
      {
        text: "mdi-whatsapp",
        link: "https://api.whatsapp.com/send?phone=67195902&text=Apunto Ga. mensaje..",
        info: "WhatsApp",
      },
      {
        text: "mdi-youtube",
        link: "https://youtube.com/channel/UCtYhPBEUQVln3pPULwpwqOw",
        info: "Youtube",
      },
      {
        text: "mdi-instagram",
        link: "https://www.instagram.com/p/CiScnnAgeCd/?igshid=YmMyMTA2M2Y=",
        info: "Instagram",
      },
      {
        text: "mdi-google-maps",
        link: "https://goo.gl/maps/oYhzRbCNc8dYHD9B9",
        info: "Ubicación en Mapa.."
      },
    ],
  }),
  methods: {},
};
</script>
