import Vue from "vue";

Vue.filter("formatFecha", (value, soloDate = false) => {
  if (!value) return "";
  const d = new Date(value);
  const date = d.toLocaleDateString();
  const time = d
    .toTimeString()
    .split(" ")[0]
    .slice(0, 5);
  return !soloDate ? `${date} ${time}` : date;
});

Vue.filter("formatColor", (value) => {
  if (value.includes("Proceso")) {
    return "green darken-1";
  } else if (value.includes("Reservado")) {
    return "deep-orange darken-4";
  } else if (value.includes("Finalizado")) {
    return "indigo darken-4";
  } else if (value.includes("Entregado")) {
    return "black";
  } 
});

